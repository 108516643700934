<template>
  <section>
    <div class="container-default">
      <h1>Modals</h1>
    </div>
    <div class="container-default">
    <p>Full functionality is only possible using the Vue 3 framework</p>
    <div class="border p-2 container-default">
      <h5>Info Modals</h5>
      <div class="grid form">
        <div class="form-group g-col-3">
          <label>Header Text</label>
          <input class="form-control" v-model="headerText" placeholder="Setzt die h3. Ist kein Pflichfeld"/>
        </div>
        <div class="form-group g-col-3">
          <label>ID</label>
          <input class="form-control" v-model="styleId" placeholder="Optionale ID zum ansteuren per css"/>
        </div>
        <div class="form-group g-col-3">
          <label>Info Message / Text</label>
          <input class="form-control" v-model="text" placeholder="Schreibe hier die Nachricht rein"/>
        </div>
      </div>
      <div class="mt-3">
        <button @click="runDemoModal" class="button button-primary">Action</button>
      </div>
    </div>
    </div>
  </section>
  <section class="container-default">
    <div class="border p-2 container-default">
      <h5>Form Modal</h5>
      <div >
      <code class="code">
<pre class="w-100">
  // Importierten und registrieren des Form Stores
  import { useFormModalStore } from "beg-comsy";
  const formModalStore = useFormModalStore();

  const newForm = formModalStore.add('Überschrift');
  const inputField = newForm.addInput('nextLine', 'Mein Inputfeld');

  const saveButton = newForm.addSaveAndClose();

  saveButton.onClick(() => {
    // Empfängt den Wert im Inputfeld
    const newInputValue = inputField.value as string;
    alert(newInputValue)
  })
</pre>
      </code>
      </div>
      <div class="mt-2">
        <button @click="runSimpleForm" class="button button-primary">Example form action</button>
        <button @click="runDemoFormModal" class="ms-2 button button-primary">More complex form action</button>
      </div>
    </div>
  </section>

<div>
  <FormTemplate :form="myForm" />
</div>
</template>

<script setup lang="ts">
import {useInfoModalStore} from "../../components/InfoModal/infoModalStore";
import {ref} from "vue"
import {useFlashMessageStore} from "../../components/FlashMessage";
import {useFormModalStore} from "../../components/FormModal/formModalStore";
import FormTemplate from "../../components/FormModal/FormTemplate.vue";
import {Form, SelectOption} from "../../components/FormModal/FormModalInterfaces";

const infoModalStore = useInfoModalStore();
const flashMessageStore = useFlashMessageStore();
const formModalStore = useFormModalStore()

const text = ref("Möchtest du ein neues Projekt anlegen?");
const headerText = ref("");
const styleId = ref("");
const myForm = ref({} as Form)

const form = new Form("Mein Testformular");
form.addInput(true, "Hallo Welt")

myForm.value = form

function runDemoModal() {
  const modal = infoModalStore.addModal(text.value)
  if (headerText.value != "") {
    modal.setHeaderText(headerText.value)
  }

  modal.addButton("Abbruch")
  modal.setCloseViaBackground(false, () => {
    flashMessageStore.addFlashMessage("Tut mir leid, du kannst das Fenster nicht über einen Klick auf des Backgroundhintergrunds schließen")
  });

  const confirmBtn = modal.addButton("Ja, Projekt erstellen", "primary")
  confirmBtn.setAutoClose(false)
  confirmBtn.isClicked(() => {
    const startFlashMessage = flashMessageStore.addFlashMessage("Simuliere das erstellen des Projektes, bitte warten ...", "primary", "bottom", 5000)
    setTimeout(() => {
      flashMessageStore.addFlashMessage("Projekt erfolgreich erstellt.")
      startFlashMessage.close()
      modal.close()
    }, 2000)
  })
}

function runSimpleForm() {
  const newForm = formModalStore.add('Überschrift');
  const inputField = newForm.addInput('nextLine', 'Mein Inputfeld');
  inputField.setIcon("fa-kit fa-beg-info", () => {

  })
  const dataschutzLabel = newForm.addTextBox(true, "Bitte lesen die die Datenschutzerklärung hier")
  dataschutzLabel.setLink("https://beg-luxomat.com", true)
  const saveButton = newForm.addSaveAndClose();

  saveButton.onClick(() => {
    // Empfängt den Wert im Inputfeld
    const newInputValue = inputField.value as string;
    alert(newInputValue)
  })
}

function runDemoFormModal() {

  interface TestInterface {
    id: number,
    name: string,
    description: string,
    meineZahl: number,
    isAwesome: boolean,
    selectBox: object
  }

  const testObject = {
    id: 2,
    name: "Der Name meines Testobjekts",
    description: "Das ist meine tolle beschreibung",
    meineZahl: 2,
    selectBox: {
      id: 2,
      name: "French",
      country: "France",
      iso: "fr"
    },
    isAwesome: false
  } as TestInterface;

  const form = formModalStore.add<TestInterface>("Beispielformular")
      .setObject(testObject)
  const saveBtn = form.addSaveAndClose()
  form.addTextBox(true,"Das tolle Formular")
  const btn = form.addButton(true, "Rufe eine neue Seite auf").setDisable()
  form.addInput(true, "Inputfeld welches eigenständig arbeitet und als Prevalue den Namen erhält", "")
      .setPlaceholder("HUHU")

  form.addMappedInput(true, "Inputfeld welches automatisch mit dem Objekt verknüpft und bearbeitet wird", 'name' )
  const checkbox = form.addMappedInput(true, 'Is Awesome', 'isAwesome', false)
      .setType('toggle')
  form.addMappedInput(true, "Inputfeld wird automatisch zum Number Input", 'meineZahl')

/*  const option = form.addMappedInput(true, 'Country', 'selectBox').setType('select')
      .addOptions({
        id: 1,
        name: "Germany",
        country: "Germany",
        iso: "de"
      } as SelectOption)
      .addOptions({
        id: 2,
        name: "French",
        country: "France",
        iso: "fr"
      }as SelectOption)*/

  const radio = form.addMappedInput(true, 'Country', 'selectBox').setType('select')
      .addOptions({
        id: 1,
        name: "Germany",
        country: "Germany",
        iso: "de"
      } as SelectOption)
      .addOptions({
        id: 2,
        name: "French",
        country: "France",
        iso: "fr"
      }as SelectOption)

  radio.setIcon()
      .onIconClick(() => {
        alert("HALLO")
      })

  saveBtn.onClick(() => {
    console.log(form.handleObject())
    console.log("WURDE AUSGEFÜHRT")
  })

}
</script>

<style scoped lang="scss">

</style>