<template>
  <div>
    <LoginForm />
  </div>
  <div class="mt-5 container-small form-border">
    <h3>Check groups</h3>
    <div class="form-input">
      <input class="form-control" v-model="data.groupName" />
    </div>
    <div class="form-input mt-1">
      <button class="button button-ghost-primary" @click="testIsGranted">Check is Granted</button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import LoginForm from "../../components/UserManager/LoginForm.vue";
import {reactive} from "vue";
import {useUserManagerStore} from "../../components/UserManager/userManagerStore";
import {useFlashMessageStore} from "../../components/FlashMessage";

const userManagerStore = useUserManagerStore();
const flashMessageStore = useFlashMessageStore();

const data = reactive({
  groupName: "ROLE_USER"
})

function testIsGranted() {
  if(userManagerStore.isGranted(data.groupName)) {
    flashMessageStore.addFlashMessage("Group is granted", "success");
  } else {
    flashMessageStore.addFlashMessage("Group is NOT granted", "danger")
  }
}

</script>