<template>
  <div class="overlay position-fixed" @mousedown.self="backgroundClicked" v-if="infoModal">
    <div class="info-modal container-small" :id="infoModal.containerId">
      <div>
        <h3 v-if="infoModal.headerText">{{ infoModal.headerText }}</h3>
        <span v-if="infoModal.closeViaBackground" class="close" @click="closeModal">x</span>
      </div>
      <template v-for="infoText of infoModal.infoTextItems" :key="infoText.id">
        <p v-html="infoText.text"></p>
      </template>

      <div v-if="infoModal.buttonItems.length > 0" class="d-flex mt-2">
        <template v-for="button of infoModal.buttonItems" :key="button.id">
          <button class="button" @click="buttonAction(button)" :class="'button-' + button.colorStyle">
            {{ button.buttonText }}<span v-if="button.isLoading" class="ms-1"><i class="fa-light fa-loader fa-spin"></i></span>
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {useInfoModalStore} from "./infoModalStore";
import {defineProps, PropType} from "vue";
import {InfoModal, InfoTextButton} from "./InfoModal";

const props = defineProps({
  infoModal: Object as PropType<InfoModal>
})
const infoModalStore = useInfoModalStore()

function closeModal() {
  if(!props.infoModal) return
  infoModalStore.removeModal(props.infoModal)
}

function buttonAction(button: InfoTextButton) {
  if(!props.infoModal) return;
  if(button.isLoading) return;
  button.setIsLoading();
  button.clickEvent();
  if(button.autoCloseModal) {
    infoModalStore.removeModal(props.infoModal)
  }
}

function backgroundClicked() {
  if(!props.infoModal) return
  if(props.infoModal.closeViaBackground) {
    closeModal()
  }
  props.infoModal.backgroundClickEvent()
}

</script>

<style lang="scss" scoped>
</style>