<template>
  <div>
    <GroupManager />
  </div>
</template>

<script setup lang="ts">
import GroupManager from "../../components/GroupManager/GroupManager.vue";
import {useGroupManagerStore} from "../../components/GroupManager/groupManagerStore";

const groupManagerStore = useGroupManagerStore()
groupManagerStore.addGroup("BACKEND_ADMIN", true, "This group is allowed to change backend user rights")
groupManagerStore.addGroup("BEG Employee", false, "This user can visit the link forge system")
groupManagerStore.addGroup("LINK_FORGE_ADMIN", true, "This user can see all redirects")
</script>

<style scoped lang="scss">

</style>