import { defineStore } from 'pinia'
import { FlashMessage, FlashMessagePosition } from './FlashMessage';
import { BaseColorClass } from '../Interfaces/ColorInterface';

export const useFlashMessageStore = defineStore('flashMessageStore', {
    state: () => ({
        flashMessageItems: [] as FlashMessage[]
    }),
    actions: {
        /**
         * 
         * @param {string} message Definiert den Text der Ausgegeben wird
         * @param colorType Bestimmt die Farbe der FlashMessage
         * @param position Gibt die Position an wo die Nachricht erscheinen soll
         * @param holdTime Verbleibedauer (in ms), bevor die Nachricht automatisch verschweinden
         * @returns 
         */
        addFlashMessage(message: string, colorType = "secondary" as BaseColorClass, position = "bottom" as FlashMessagePosition, holdTime = 2500 as number): FlashMessage {
            const flashMessage = new FlashMessage();
            flashMessage.message = message;
            flashMessage.colorType = colorType;
            flashMessage.position = position;
            flashMessage.holdTime = holdTime;
            this.flashMessageItems.push(flashMessage);

            return flashMessage;
        },
        removeItem(flashMessage: FlashMessage) {
            const index = this.flashMessageItems.findIndex(obj => obj.id == flashMessage.id)
            this.flashMessageItems.splice(index, 1)
        }
    }
})