import { defineStore } from 'pinia'
import { InfoModal } from "./InfoModal";

export const useInfoModalStore = defineStore('infoModalStore', {
    state: () => ({
        infoModalItems: [] as InfoModal[]
    }),
    actions: {
        /**
         *
         * @param infoText Welche Information soll ausgegeben werden
         */
        addModal(infoText: string) {
            const infoModal = new InfoModal();
            infoModal.addInfoText(infoText)
            this.infoModalItems.push(infoModal)
            return infoModal;
        },
        removeModal(modal: InfoModal) {
            const index = this.infoModalItems.findIndex(obj => obj.id == modal.id)
            if(index<0) return
            this.infoModalItems.splice(index, 1)
        }
    }
})