import {v4} from "uuid";
import {BaseColorClass, BaseGhostColorClass} from "../Interfaces";
import {I18nComsy} from "../translations/i18nComsy";
import {useFormModalStore} from "./formModalStore";
import moment from "moment"
import {reactive} from "vue";

type FormInputTypes = "text"|"number"|"select"|"textarea"|"password"|"date"|"radio"|"checkbox"|"toggle"

export interface DefaultObject {
    [key: string]: any;
}

export class Form <T extends object = DefaultObject>{

    uuid = v4()
    firstItem = {} as FormItem
    formItems = [] as FormItem[]
    lastItem = {} as FormItem
    header = ""
    translation: any
    mandatory = false
    usedObject: any|null

    constructor(header: string|null = null) {
        if(header) this.header = header
        this.translation = I18nComsy.getI18nInstance()
        this.firstItem = reactive(new FormItem())
        this.lastItem = reactive(new FormItem())
        this.usedObject = null
    }

    getAllInput(): Input[] {
        const allInputs = [] as Input[]
        for(const input of this.firstItem.inputs) {
            allInputs.push(input)
        }
        for(const item of this.formItems) {
            for(const input of item.inputs) {
                allInputs.push(input)
            }
        }
        for(const input of this.lastItem.inputs) {
            allInputs.push(input)
        }
        return allInputs
    }

    getAllButtons(): Button[] {
        const allButtons = [] as Button[]
        for(const btn of this.firstItem.buttons) {
            allButtons.push(btn)
        }
        for(const item of this.formItems) {
            for(const btn of item.buttons) {
                allButtons.push(btn)
            }
        }
        for(const btn of this.lastItem.buttons) {
            allButtons.push(btn)
        }
        return  allButtons
    }

    setObject(usedObject: T) {
        this.usedObject =  { ...usedObject };
        return this;
    }

    /**
     * Setze das Formular als Pflicht Formular. Dadurch, kann das Formular nicht über einen Klick auf den Hintergrund geschlossen werden
     * @param value
     * @return Form
     */
    setMandatory(value = true) {
        this.mandatory = value
        return this
    }

    newFormItem() {
        const formItem = reactive(new FormItem());
        this.formItems.push(formItem)
        return formItem
    }

    addTextBox(position: "sameLine"|"nextLine"|"firstLine"|"lastLine"|false|true,
             message: string) {
        const textBox = reactive(new TextBox());
        textBox.message = message

        if((position == "nextLine" || this.formItems.length !== 0) || position === true) {
            const item = new FormItem()
            item.textBoxes.push(textBox)
            this.formItems.push(item)
        } else if(position == "sameLine" || position === false) {
            this.formItems[this.formItems.length-1].addLabel(textBox)
        } else if(position == "firstLine") {
            this.firstItem.addLabel(textBox)
        } else if(position == "lastLine") {
            this.lastItem.addLabel(textBox)
        }
        return textBox
    }

    addInput(position: "sameLine"|"nextLine"|"firstLine"|"lastLine"|false|true,
             label: string,
             preValue = "" as string|number|null,
             isMandatory = false
    ) {
        const input = reactive(new Input());
           input.label = label;
            input.value = preValue;
            input.preValue = preValue;
            input.detectType()
            input.isMandatory = isMandatory;
            if((position == "nextLine" || this.formItems.length !== 0) || position === true) {
                const item = new FormItem()
                item.inputs.push(input)
                this.formItems.push(item)
            } else if(position == "sameLine" || position === false) {
                this.formItems[this.formItems.length-1].addInput(input)
            } else if(position == "firstLine") {
                this.firstItem.addInput(input)
            } else if(position == "lastLine") {
                this.lastItem.addInput(input)
            }
            return input;
    }

    addMappedInput(newLine:"sameLine"|"nextLine"|"firstLine"|"lastLine"|false|true,
                   label: string,
                   objectKey = "" as keyof T,
                   isMandatory = false): Input {
        if(this.usedObject === null) {
            console.warn("Kein Objekt gewählt")
        }
        const preValue = this.usedObject[objectKey] as string|number|null;
        return this.addInput(newLine, label, preValue, isMandatory)
            .map(objectKey as string);
    }

    addButton(position: "sameLine"|"nextLine"|"firstLine"|"lastLine"|false|true,
                label: string,
                style: BaseColorClass|BaseGhostColorClass = "primary",
                ignoreMandatory = false
    ) {
        const button = reactive(new Button());
        button.label = label
        button.style = style
        button.ignoreMandatory = ignoreMandatory
        if((position == "nextLine" || (this.formItems.length !== 0 && position != "lastLine" && position != "firstLine")) || position === true) {
            const item = new FormItem()
            item.buttons.push(button)
            this.formItems.push(item)
        } else if(position == "sameLine" || position === false) {
            this.formItems[this.formItems.length-1].addButton(button)
        } else if(position == "firstLine") {
            this.firstItem.addButton(button)
        } else if(position == "lastLine") {
            this.lastItem.addButton(button)
        }
        return button
    }

    addCloseButton() {
        return this.addButton('lastLine', this.translation.t('formModal.close'), "ghost-primary", true)
            .setCloseFormOnClick()
    }

    addSaveButton() {
        return this.addButton('lastLine', this.translation.t('formModal.save'), 'primary')
            .setCloseFormOnClick()
            .setFaIcon("fa-kit fa-beg-one-projects-button-save")
            .setClickedByEnter()
    }

    addSaveAndClose() {
        this.addCloseButton()
        return this.addSaveButton()
    }

    close() {
        useFormModalStore().removeModal(this)
    }

    handleObject(handleObject: object|null = null): T|null {
        if(handleObject) this.usedObject = handleObject
        if(!this.usedObject) return null
        const inputs = [] as Input[]
        for(const formItem of this.formItems) {
            const inputItems = formItem.inputs.filter(obj => obj.objectField != null)
            for(const input of inputItems) {
                inputs.push(input)
            }
        }

        for(const input of inputs) {
            if(!input.objectField ) continue;
            this.usedObject[input.objectField] = input.value
        }
        this.resetPreValues()
        return this.usedObject
    }

    resetPreValues() {
        for(const item of this.formItems) {
           item.resetPreValues()
        }
        this.lastItem.resetPreValues()
        this.firstItem.resetPreValues();
    }

    isChanged() {
        for(const item of this.formItems) {
            if(item.isChanged()) return true
        }
        if(this.lastItem.isChanged()) return true
        return this.firstItem.isChanged();
    }
}

export class FormItem {
    uuid = v4()
    inputs = [] as Input[]
    buttons = [] as Button[]
    textBoxes = [] as TextBox[]

    addLabel(textBox: TextBox) {
        this.textBoxes.push(textBox)
    }

    addButton(button: Button) {
        this.buttons.push(button)
    }

    addInput(input: Input) {
        this.inputs.push(input)
    }

    isChanged() {
        for(const input of this.inputs) {
            if(input.isChanged()) return true
        }
        return false
    }

    resetPreValues() {
        for(const input of this.inputs) {
            input.preValue = input.value
        }
    }
}

export class Input {

     i18n: any
    constructor(/*translation: ComposerTranslation*/) {
        this.i18n = I18nComsy.getI18nInstance()
        this.errorMessage = this.i18n.t('formModal.errorMessage')
        // this.errorMessage = this.translation('formModal.errorMessage')

    }

    uuid = v4()
    label = ""
    type = "text"
    preValue = "" as string|number|null|object
    value = "" as string|number|null|object
    isMandatory = false
    isMissing = false
    errorMessage = ""
    placeholder = ""
    optionValues: (string | number | SelectOption)[] = []
    objectField = null as string|number|null
    isDisable = false
    icon = ""
    iconClickEvent: (() => void) | null = null

    setPlaceholder(value: string) {
         this.placeholder = value;
         return this
    }

    setType(type: FormInputTypes) {
        this.type = type;
        if(type === "date") {
            this.preValue = moment(this.value).format('YYYY-MM-DD')
            this.value = moment(this.value).format('YYYY-MM-DD')
        }
        return this
    }

    detectType() {
        if(typeof this.value ==  "number") {
            this.type = "number"
            return this
        }
        this.type = "text"
        return this;
    }

    map(field: string|number) {
        this.objectField = field;
        return this
    }

    addOptions(optionOrOptions: string[]|number[]|SelectOption[]|string|number|SelectOption) {
         if(Array.isArray(optionOrOptions)) {
            for(const option of optionOrOptions) {
                this.optionValues.push(option)
            }
         } else {
             this.optionValues.push(optionOrOptions)
         }
         return this
    }

    //Wurde das Inputfeld verändert?
    isChanged() {
         return (this.value !== this.preValue)
    }

    setDisable(value = true) {
         this.isDisable = value
        return this
    }

    setIcon(iconName = "fa-kit fa-beg-info", iconClickEvent = () => {}) {
        this.icon = iconName
        this.onIconClick(iconClickEvent)
        return this
    }

    onIconClick(data: () => void) {
        this.iconClickEvent = data
        return this
    }
}

export class Button {
    uuid = v4()
    label = ""
    faIcon = ""
    style: BaseColorClass|BaseGhostColorClass = "primary"
    ignoreMandatory = false
    isLoading = false
    onClickEvent: (() => void) | null = null
    clickedByEnter = false
    closeFormOnClick = false
    isDisable = false

    setCloseFormOnClick(value = true) {
        this.closeFormOnClick = value
        return this
    }

    setFaIcon(faIcon: string) {
        this.faIcon = faIcon
        return this
    }

    onClick(data: () => void) {
        this.onClickEvent = data;
        return this
    }

    /**
     * // Bestimmt, ob beim Enter Klick eines Input feldes das onClick Event ausgeführt wird. z.B. Speichern
     * @param value
     */
    setClickedByEnter(value= true) {
        this.clickedByEnter = value
        return this
    }

    setDisable(value = true) {
        this.isDisable = value
        return this
    }
}

export class TextBox {
    uuid = v4()
    message = ""
    cssClass = [] as string[]
    link = ""
    linkNewTarget = false

    addCssClass(cssClass: string|string[]) {
        if(Array.isArray(cssClass)) {
            for(const newCssClass of cssClass) {
                if(!this.cssClass.find(obj => obj == newCssClass)) {
                    for(const splitNewCssClass of newCssClass.split(" ")) {
                        this.cssClass.push(splitNewCssClass);
                    }
                }
            }
        } else {
            for(const splitNewCssClass of cssClass.split(" ")) {
                this.cssClass.push(splitNewCssClass);
            }
        }
    }

    removeCssClass(cssClass: string|string[]) {
        if(Array.isArray(cssClass)) {
            for(const removeCssClass of cssClass) {
                for(const splitNewCssClass of removeCssClass.split(" ")) {
                    const index = this.cssClass.findIndex(obj => obj == splitNewCssClass);
                    if(index >= 0) {
                        this.cssClass.splice(index, 1);
                    }
                }
            }
        } else {
            for(const splitNewCssClass of cssClass.split(" ")) {
                const index = this.cssClass.findIndex(obj => obj == splitNewCssClass);
                if(index >= 0) {
                    this.cssClass.splice(index, 1);
                }
            }
        }
    }

    setLink(link: string, newTarget = false) {
        this.link = link
        this.linkNewTarget = newTarget
    }
}

export interface SelectOption {
    id: string|number
    name: string
}
