<template>
  <div v-if="inputElement && inputElement.type != 'radio' && inputElement.type != 'checkbox' && inputElement.type != 'toggle'">
  <label>
    {{ inputElement.label }}
    <span v-if="inputElement.icon" class="pointer" @click="inputElement.iconClickEvent? inputElement.iconClickEvent(): ''">
      <i :class="inputElement.icon"></i>
    </span>
  </label>
    <input
        class="w-100 h-100"
        :type="getInputType"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @keydown.enter="inputKeyDownEnterEvent"
        :disabled="inputElement.isDisable"
        :placeholder="inputElement.placeholder"
    />
  </div>

  <div v-if="inputElement && (inputElement.type == 'checkbox' || inputElement.type == 'toggle' )">
    {{inputElement.placeholder}}
    <input
        :id="'checkbox_'+inputElement.uuid"
        class="w-100 h-100"
        :class="getInputClass"
        :type="getInputType"
        :value="modelValue"
        @input="$emit('update:modelValue', !modelValue)"
        @keydown.enter="inputKeyDownEnterEvent"
        name="checkbox"
        :disabled="inputElement.isDisable"
        :checked="modelValue"
    />
    <label :for="'checkbox_'+inputElement.uuid" :class="getInputClass">{{ inputElement.label }}
      <span v-if="inputElement.icon" class="pointer" @click="inputElement.iconClickEvent? inputElement.iconClickEvent(): ''">
        <i :class="inputElement.icon"></i>
      </span>
    </label>
  </div>
</template>

<script setup lang="ts">
import {Input} from "../FormModalInterfaces";
import {PropType, defineProps, defineEmits, computed} from "vue";

const props = defineProps({
  inputElement: Object as PropType<Input>,
  modelValue: [String, Number, Boolean]
})

const emits = defineEmits(['update:modelValue','inputKeyDownEnterEvent'])

function inputKeyDownEnterEvent() {
  emits('inputKeyDownEnterEvent', props.inputElement)
}

const getInputType = computed(() => {
  if(!props.inputElement) return "text";
  if(props.inputElement.type === 'toggle') return "checkbox"
  return props.inputElement.type
})

const getInputClass = computed(() => {
  if(!props.inputElement) return "";
  if(props.inputElement.type === 'toggle') return "toggle"
  return ""
})
</script>