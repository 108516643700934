import { BaseColorClass } from '../Interfaces/ColorInterface';
import { v4 } from "uuid";
import {useFlashMessageStore} from "./flashMessageStore";
export class FlashMessage {
    id = v4();
    message = "";
    colorType = "" as BaseColorClass;
    position = "bottom" as FlashMessagePosition;
    holdTime = 2500;

    close() {
        const flashMessageStore = useFlashMessageStore();
        flashMessageStore.removeItem(this);
    }
}

export type FlashMessagePosition = "top"|"bottom";