<template>
  <section class="background-light-grey">
    <div class="container-default">
      <h1>Flash Messages</h1>
      <div class="form grid">
        <div class="form-group g-col-3">
          <label>Nachricht</label>
          <input class="form-control" placeholder="Message" v-model="messageString">
        </div>
        <div class="form-group g-col-3">
          <label>Farbe / Style</label>
          <input class="form-control" placeholder="Message" v-model="colorType">
        </div>
        <div class="form-group g-col-3">
          <label>Position/label</label>
          <select v-model="position" class="form-control">
            <option value="top">Top</option>
            <option value="bottom" selected>Bottom</option>
          </select>
        </div>
        <div class="form-group g-col-3">
          <label>Haltedauer in ms</label>
          <input type="number" class="form-control" placeholder="Message" v-model="delayTime">
        </div>
        <div class="g-col-3">
          <button class="button button-primary" @click="runMessage">Nachricht testen</button>
        </div>
      </div>
      <div class="mt-2">
        <a href="http://192.168.222.134:3000/Marketing/BEG-Comsy/wiki#user-content-flash-message-flash-message" target="_blank">
          <button class="button button-primary">See documentation</button>
        </a>
      </div>
    </div>
  </section>

</template>

<script lang="ts" setup>
import { useFlashMessageStore } from "@/../components/FlashMessage"
import { ref } from "vue";
import { BaseColorClass } from '../../components/Interfaces';
import { FlashMessagePosition } from '../../components/FlashMessage';
import {useCounterStore} from "../../components/InfoModal/infoModalStore";

const messageString = ref("Das ist meine tolle Nachricht")
const colorType = ref("success")
const position = ref("bottom")
const delayTime = ref(2500)

const flashMessageStore = useFlashMessageStore()
function runMessage() {
    flashMessageStore.addFlashMessage(messageString.value, 
    colorType.value as BaseColorClass, 
    position.value as FlashMessagePosition, 
    delayTime.value )
}

</script>

<style lang="scss" scoped>
.mt-2 {
    margin-top: 4px;
}
</style>