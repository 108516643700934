import en from "./i18n/en.json"
import de from "./i18n/de.json"
// import cz from "./cz.json"
// import dk from "./dk.json"
// import es from "./es.json"
// import fr from "./fr_FR.json"
// import frBE from "./fr_BE.json"
// import hu from "./hu.json"
// import it from "./it.json"
// import nl from "./nl.json"
// import nlBE from "./nl_BE.json"
// import pl from "./pl.json"
// import pt from "./pt.json"

const translationMessages = () => {
    return {
        en: en,
        de: de
        // dk: dk,
        // es: es,
        // fr: fr,
        // fr_BE: frBE,
        // hu: hu,
        // it: it,
        // nl: nl,
        // nl_BE: nlBE,
        // pl: pl,
        // pt: pt
    }
}

export default translationMessages;

