<template>
    <div class="alert" :class="getColorStyle" v-if="flash" >
        <span class="top-right" @click="removeItem"><i class="fa-light fa-x"></i></span>
        {{ flash.message }}
    </div>
</template>

<script lang="ts" setup>
import { defineProps, PropType, computed, onMounted } from "vue"
import { FlashMessage } from './FlashMessage';
import { useFlashMessageStore } from './flashMessageStore';

const flashMessageStore = useFlashMessageStore();

const props = defineProps({
    flash: Object as PropType<FlashMessage>
})

const getColorStyle = computed(() => {
    if(!props.flash) return "alert-primary"
    return "alert-" + props.flash.colorType
})

function removeItem() {
    if(!props.flash) return
    flashMessageStore.removeItem(props.flash)
}

onMounted(() => {
    if(!props.flash) return
    setTimeout(() => {
        removeItem()
    }, props.flash.holdTime)
})


</script>

<style lang="scss" scoped>
.top-right {
    position: absolute;
    top: 0;
    right: 0;
}

.alert {
    text-align: center;
    width: 360px;
    margin: auto;
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-radius: .25rem;
}

.alert-primary {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}

.alert-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-dark {
  color: #141619;
  background-color: #d3d3d4;
  border-color: #bcbebf;
}
</style>