<template>
  <div class="container-default">
    <section>

      <h1>Basis-Stylings</h1>
      <h2>Überschriften</h2>
      <p>Für die Headlines können sowohl die Tags, als auch die Klassen genutzt werden.
        Beispielsweise kann ein Textelement mit einer headline-Klasse versehen werden, um sie entsprechend der Überschrift zu stylen,
        sie aber nicht als dergleichen zu deklarieren.</p>
      <div class="d-flex align-items-center">
        <h1>H1 Headline</h1><code class="code"><pre>&lt;h1 class="h1">H1 Headline&lt;/h1></pre></code>
      </div>
      <div class="d-flex align-items-center">
        <h2>H2 Headline</h2><code class="code"><pre>&lt;h2 class="h2">H2 Headline&lt;/h2></pre></code>
      </div>
      <div class="d-flex align-items-center">
        <h3>H3 Headline</h3><code class="code"><pre>&lt;h3 class="h3">H3 Headline&lt;/h3></pre></code>
      </div>
      <div class="d-flex align-items-center">
        <h4>H4 Headline</h4><code class="code"><pre>&lt;h4 class="h4">H4 Headline&lt;/h4></pre></code>
      </div>
      <div class="d-flex align-items-center">
        <h5>H5 Headline</h5><code class="code"><pre>&lt;h5 class="h5">H5 Headline&lt;/h5></pre></code>
      </div>
      <div class="d-flex align-items-center">
        <h6>H6 Headline</h6><code class="code"><pre>&lt;h6 class="h6">H6 Headline&lt;/h6></pre></code>
      </div>
    </section>
    <section>
      <h3>Font-Size Klassen</h3>
      <p>Die Schriftgrößen sind responsiv</p>
      <div class="fs-small">Font size small | 1rem - 1.4rem
        <code class="code"><pre>class="fs-small"</pre></code>
      </div>
      <div class="fs-default">Font size default | 1.2rem - 1.8rem
        <code class="code"><pre>class="fs-default"</pre></code>
      </div>
      <div class="fs-medium">Font size medium | 1.6rem - 2.1rem
        <code class="code"><pre>class="fs-medium"</pre></code>
      </div>
      <div class="fs-large">Font size large | 1.8rem - 2.4rem
        <code class="code"><pre>class="fs-large"</pre></code>
      </div>
    </section>
    <section class="background-light-grey p-3">
      <p>
        <strong>Fließtext</strong><br>
        Unser Corporate Design verkörpert die Essenz unserer Marke in jeder Facette. Mit einer sorgfältig abgestimmten
        Kombination aus Farben, Schriften und Grafiken strahlt es Professionalität, Stil und Einzigartigkeit aus. Jedes
        Element wurde mit Blick auf Konsistenz und Wiedererkennbarkeit gestaltet, um sicherzustellen, dass unsere Marke
        in jeder Interaktion einen bleibenden Eindruck hinterlässt.
        <br/><br/>
        Die Farbpalette wurde strategisch gewählt, um unsere Markenpersönlichkeit widerzuspiegeln und eine emotionale
        Verbindung zu unseren Kunden herzustellen. Von kräftigen Primärfarben bis hin zu subtilen Nuancen strahlen
        unsere Farben Kraft und Vertrauen aus.
        <br/>
        Unsere Auswahl an Schriftarten wurde sorgfältig getroffen, um Lesbarkeit, Ästhetik und Markenidentität zu
        optimieren. Ob in gedruckten Materialien oder auf digitalen Plattformen, unsere Schriftarten verleihen unseren
        Botschaften Autorität und Glaubwürdigkeit.
        <br/><br/>
        Grafische Elemente spielen eine entscheidende Rolle, indem sie visuelles Interesse wecken und unsere
        Markenbotschaften verstärken. Von ikonischen Logos bis hin zu subtilen Mustern ergänzen diese Grafiken unser
        Design, um eine kohärente und ansprechende visuelle Identität zu schaffen.
        <br/>
        Unser Corporate Design ist die Grundlage für unseren Erfolg und wird uns dabei unterstützen, unsere Ziele zu
        erreichen und unsere Vision zu verwirklichen.
      </p><code class="code"><pre>&lt;p>Beliebiger Text, der dann so dargestellt wird, wie der Fließtext-Block&lt;/p></pre>
      </code>
    </section>
    <section>
      <div>
        <p>
          Es gibt fünf verschiedene Buttons:
        </p>
        <ul class="list-style-none">
          <li>
            <div class="d-flex align-items-center">
              <button class="button button-primary">Button-Primary</button><code class="code"><pre>&lt;button class="button button-primary">Buttontext&lt;button></pre></code>
            </div>
          </li>
          <li>
            <div class="d-flex align-items-center">
              <button class="button button-ghost-primary">Button-Ghost-Primary</button><code class="code"><pre>&lt;button class="button button-ghost-primary">Buttontext&lt;button></pre></code>
            </div>
          </li>
          <li class="background-light-grey">
            <div class="d-flex align-items-center">
              <button class="button button-ghost-white">Button-Ghost-White</button><code class="code"><pre>&lt;button class="button button-ghost-white">Buttontext&lt;button></pre></code>
            </div>
          </li>
          <li>
            <div class="d-flex align-items-center">
              <button class="button button-secondary">Button-Secondary</button><code class="code"><pre>&lt;button class="button button-secondary">Buttontext&lt;button></pre></code>
            </div>
          </li>
          <li>
            <div class="d-flex align-items-center">
              <button class="button button-ghost-secondary">Button-Ghost-Secondary</button><code class="code"><pre>&lt;button class="button button-ghost-seconary">Buttontext&lt;button></pre></code>
            </div>
          </li>
          <li>
            <div class="d-flex align-items-center">
              <button class="button button-tertiary">Button-Tertiary</button><code class="code"><pre>&lt;button class="button button-tertiary">Buttontext&lt;button></pre></code>
            </div>
          </li>
          <li>
            <div class="d-flex align-items-center">
              <button class="button button-ghost-tertiary">Button-Ghost-Tertiary</button><code class="code"><pre>&lt;button class="button button-ghost-tertiary">Buttontext&lt;button></pre></code>
            </div>
          </li>
        </ul>
        <div>
          <p>
            Alle Buttons benötigen die generelle "button" Klasse. Die Button-Stylings lassen sich aber auch in anderen HTML-Elementen, wie z.B. in einem
            <code><pre>span</pre></code>
            oder <code><pre>a</pre></code> - Tag nutzen
          </p>
          <div class="d-flex my-2">
            <span class="button button-primary">Dies ist ein Span, als Button verkleidet</span>
            <a class="button button-ghost-primary">Dies ist ein a-Tag, als Button verkleidet</a>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <div class="background-primary button-wrapper d-flex flex-column p-5">
          <button class="button button-primary">Button-Primary</button>
          <button class="button button-ghost-primary">Button-Ghost-Primary</button>
          <button class="button button-secondary">Button-Secondary</button>
          <button class="button button-ghost-secondary">Button-Ghost-Secondary</button>
          <button class="button button-ghost-white">Button-Ghost-White</button>
        </div>
        <div class="background-dark-grey button-wrapper d-flex flex-column p-5">
          <button class="button button-primary">Button-Primary</button>
          <button class="button button-ghost-primary">Button-Ghost-Primary</button>
          <button class="button button-secondary">Button-Secondary</button>
          <button class="button button-ghost-secondary">Button-Ghost-Secondary</button>
          <button class="button button-ghost-white">Button-Ghost-White</button>
        </div>
        <div class="background-light-grey button-wrapper d-flex flex-column p-5">
          <button class="button button-primary">Button-Primary</button>
          <button class="button button-ghost-primary">Button-Ghost-Primary</button>
          <button class="button button-secondary">Button-Secondary</button>
          <button class="button button-ghost-secondary">Button-Ghost-Secondary</button>
          <button class="button button-ghost-white">Button-Ghost-White</button>
        </div>
        <div class="background-lighter-grey button-wrapper d-flex flex-column p-5">
          <button class="button button-primary">Button-Primary</button>
          <button class="button button-ghost-primary">Button-Ghost-Primary</button>
          <button class="button button-secondary">Button-Secondary</button>
          <button class="button button-ghost-secondary">Button-Ghost-Secondary</button>
          <button class="button button-ghost-white">Button-Ghost-White</button>
        </div>
      </div>
    </section>
    <section>
      <div class="d-flex">
        <div class="d-flex background-primary p-5">
          <span class="loading-spinner"></span>
        </div>
        <div class="d-flex background-dark-grey p-5">
          <span class="loading-spinner"></span>
        </div>
        <div class="d-flex background-light-grey p-5">
          <span class="loading-spinner"></span>
        </div>
        <div class="d-flex background-lighter-grey p-5">
          <span class="loading-spinner"></span>
        </div>
      </div>
    </section>
    <div class="m-5">
        <ProgressBar :value="processValue" :max="maxValue" :type="'bar'" />
        <ProgressBar :value="processValue" :max="maxValue" :type="'circle'" />
    </div>
    <section class="background-light-grey p-3">
      <table class="table">
        <thead>
        <tr>
          <th>
            Überschrift 1
          </th>
          <th>
            Überschrift 2
          </th>
          <th>
            Überschrift 3
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td data-th="Überschrift 1">Content 1-1</td>
          <td data-th="Überschrift 2">Content 1-2</td>
          <td data-th="Überschrift 3">Content 1-3</td>
        </tr>
        <tr>
          <td data-th="Überschrift 1">Content 2-1</td>
          <td data-th="Überschrift 2">Content 2-2</td>
          <td data-th="Überschrift 3">Content 2-3</td>
        </tr>
        <tr>
          <td data-th="Überschrift 1">Content 3-1</td>
          <td data-th="Überschrift 2">Content 3-2</td>
          <td data-th="Überschrift 3">Content 3-3</td>
        </tr>
        </tbody>
      </table>
      <div>Wichtig! Drauf achten, dass für die responsive Tabelle im <code><pre>td</pre></code> das Attribut data-th mit der Überschrift der Spalte enthält, da dies in der mobilen Ansicht gerendert wird. </div>
      <code class="code"><pre>&lt;table class="table">
  &lt;thead>
    &lt;tr>
      &lt;th>Überschrift&lt;/th>
    &lt;/tr>
  &lt;/thead>
  &lt;tbody>
    &lt;tr>
      &lt;td data-th="Überschrift aus th-Feld">Content&lt;/td>
    &lt;/tr>
  &lt;/tbody>
&lt;/table></pre></code>
    </section>
    <section>
      <code class="code">
        <pre>&lt;code class="code">
  &lt;pre>
      &lt;gewünschter Codeschnipsel>
  &lt;/pre>
&lt;/code></pre>
      </code>
      <br>
      <code>
        <pre>function() {
  return 500;
}</pre>
      </code>
    </section>
  </div>
  <div style="width: 300px">
    <label :style="'--p:'+processValue/maxValue*100+';--c: #4c3a6a;'"> {{ (processValue/maxValue*100).toFixed(1) }} % <progress :max="maxValue" :value="processValue">100%</progress></label>
  </div>
</template>
<script lang="ts" setup>
import {ref} from "vue";
import ProgressBar from "../../components/ProgressBar/ProgressBar.vue";

const processValue = ref(0)
const maxValue = ref(250)
let direction = ref("up")
function simulateProcess() {
  if(direction.value == "up") {
    processValue.value += 1
  } else {
    processValue.value -= 1
  }
  if(processValue.value >= maxValue.value) {
    direction.value = "down"
  }
  if(processValue.value <= 0) {
    direction.value = "up"
  }
  setTimeout(() => {
    simulateProcess()
  }, 5)
}

simulateProcess()



</script>

<style scoped lang="css">


</style>