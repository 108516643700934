import {ApiResponseInterface} from "../ApiConnection/ApiRequestInterface";
import {v4} from "uuid";
import {User} from "../UserManager/UserManagerInterfaces";
export class GroupManagerSearchRequest {
    searchString = ""
    allDataFound = false
    isLoading = false
    lastResponse = {} as ApiResponseInterface
    getRequestString() {
        return "?searchString="+this.searchString
    }
}

export class GroupManagerResult {
    id = v4()
    groupName = "BACKEND_ADMIN"
    description = "This group is allowed to change backend user rights"
    searchRequest= new GroupManagerSearchRequest()
    groupUsers = [] as User[]
    allDataFound = false
    isLoading = false
    isBackendGroup = true
    pageId = 1
    maxResult = 30
    isShowing = false
}