<template>
  <div v-if="groupManagerStore.currentGroupManagerResult">
  <div class="grid">
      <div class="g-col-12 d-flex" v-if="groupManagerStore.currentGroupManagerResult">
        <input @keydown.enter="searchUsers"
               class="form-input h-100"
               placeholder="Search for group user"
               v-model="groupManagerStore.currentGroupManagerResult.searchRequest.searchString" />
        <button class="button button-primary h-100">
          <span v-if="!isLoading"><i class="fa-kit fa-beg-one-home-widget-search fa-lg"></i></span>
          <span v-if="isLoading" class="loading-spinner text-primary"></span>
        </button>
      </div>
    </div>
    <div class="mt-1 text-start d-flex justify-content-between" v-if="groupManagerStore.currentGroupManagerResult.searchRequest">
      <div v-if="groupManagerStore.currentGroupManagerResult.searchRequest.lastResponse.info && groupManagerStore.currentGroupManagerResult.searchRequest.lastResponse.info.pagination">
        Found group users: {{groupManagerStore.currentGroupManagerResult.searchRequest.lastResponse.info.pagination.resultCount }}
      </div>
      <button v-if="groupManagerStore.currentGroupManagerResult.isBackendGroup" class="button" @click="openNewGroupMember">Hinzufügen</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import {useGroupManagerStore} from "./groupManagerStore";
import {useFormModalStore} from "../FormModal/formModalStore";
import {PropType, ref, defineProps} from "vue";
import {GroupManagerResult} from "./GroupManagerInterfaces";
const groupManagerStore = useGroupManagerStore();
const formModalStore = useFormModalStore();
const isLoading = ref(false)

const props = defineProps({
  'groupManagerResult': Object as PropType<GroupManagerResult>
})

async function searchUsers() {
  if(!props.groupManagerResult) return
  isLoading.value = true
  await groupManagerStore.getGroupMembers(props.groupManagerResult, true)
  isLoading.value = false
}
function openNewGroupMember() {
  if(!props.groupManagerResult) return
  const form = formModalStore.add("Add new group user")
  form.addLabel(true, "Please write the email of the user you want to add to group " + props.groupManagerResult.groupName)
  const input =  form.addInput(true, 'Email adresse', '', true)
  const saveBtn = form.addSaveAndClose()
      .setCloseFormOnClick(false)
  saveBtn.onClick(async () => {
    if(!props.groupManagerResult) return
    await groupManagerStore.addUserPerEmail(props.groupManagerResult, input.value as string)
    await groupManagerStore.getGroupMembers(props.groupManagerResult, true)
    form.close()
  })

}

</script>

<style scoped lang="scss">

</style>