/* eslint-disable @typescript-eslint/no-explicit-any */
import {v4} from "uuid";
import {useUnitConverter} from "./unitConverterStore";
import {ConvertUnits, Units} from "./interfaces";
import { REAL, METRIC } from "./definitions";
import {I18nComsy} from "../translations/i18nComsy";

const CONVERSION_KEY = {
    METRIC: METRIC,
    REAL: REAL
}


export class BegUnit {
    i18n = null as null|any
    id=  v4()
    private value = 0
    private convertedValue = 0
    private convertedUnit = {} as ConvertUnits
    private unitType = "metric" as Units
    private unit = "W"
    private local = "en"
    private fractionDigits = 2
    private minimumPreDecimalPlaced = 2
    private unitAbbreviation = true // wenn es auf True steht werden die Größeneinheiten abgekürzt z.B. Kilo => k

    constructor(value: number|string, unit= "") {
        this.i18n = I18nComsy.getI18nInstance()
        this.setLocal()
        this.setValue( value )
        this.setUnit(unit)
    }

    // Definiere die Sprache
    setLocal(value = null as null|string) {
        if(value) {
            this.local = value
            return
        }
        const unitStore = useUnitConverter()
        if(unitStore.locale) {
            this.local = unitStore.locale
        }
        if(document.documentElement.lang) {
            this.local = document.documentElement.lang
            return;
        }
        console.warn("Es konnte kein Sprachkürzel gefunden werden. Setze diese im UnitConverterStore oder im als lang tag in html");
    }

    setValue(value: number|string) {
        if(typeof value == "string") {
            this.value = parseFloat(value.toString().replace(",","."))
        } else {
            this.value = value
        }
    }

    setUnitType(unitType: Units) {
        this.unitType = unitType
    }

    setUnit(unit = "") {
        this.unit = unit
    }

    setIsUnitAbbreviation(value: boolean) {
        this.unitAbbreviation = value
    }

    /**
     * Formatiert die Zahl in das gewünschte Format
     * @param minimumPreDecimalPlaced Wie viele Stellen sollen es mindestens vor dem Komma sein
     * @param fractionDigits Wie viele Decimalstellen soll der String erhalten
     * @param unitAbbreviation Wird die Einheit ausgeschrieben, dann sollte auch die Maßeinheit ausgeschrieben werden
     */
    format(minimumPreDecimalPlaced = null as null|number,
           fractionDigits = null as null|number,
           unitAbbreviation = null as null|boolean) {
        if(minimumPreDecimalPlaced != undefined) {
            this.minimumPreDecimalPlaced = minimumPreDecimalPlaced
        }
        if(fractionDigits != undefined) {
            this.fractionDigits = fractionDigits
        }
        if(unitAbbreviation) {
            this.unitAbbreviation = unitAbbreviation
        }
        // Im Falle von realen Zahlen wird die das Kürzel direkt hinter die Zahl geschrieben z.B. 40T. Tonnen
        if(this.unitAbbreviation && this.unitType == 'real') {
            return this.modifyNumber() + " " + this.getUnitString()
        }
        if(this.unitType == "date") {
            return this.modifyDate()
        }
        return this.modifyNumber() + " " + this.getUnitString()
    }

    private modifyNumber() {
        if(this.unitType == 'metric') {
            return this.modifyMetricNumber()
        }
        if(this.unitType == 'real') {
            return this.modifyRealNumber()
        }
    }

    private modifyDate() {
        return this.modifyDateNumber();
    }

    private modifyMetricNumber() {
        if(this.value == 0) {
            return (this.value).toLocaleString(this.local, {
                minimumFractionDigits: this.fractionDigits,
                maximumFractionDigits: this.fractionDigits
            })
        }
        for(const unit of CONVERSION_KEY.METRIC) {
            if((this.value / (10**(this.minimumPreDecimalPlaced-1))) > (unit.formula-1)) {
                this.convertedUnit = unit
                return (this.value / unit.formula).toLocaleString(this.local, {
                    minimumFractionDigits: this.fractionDigits,
                    maximumFractionDigits: this.fractionDigits
                })
            }
        }
        return "Value is too low"
    }

    private modifyRealNumber() {
        if(this.value == 0) {
            return (this.value).toLocaleString(this.local, {
                minimumFractionDigits: this.fractionDigits,
                maximumFractionDigits: this.fractionDigits
            })
        }
        for(const unit of CONVERSION_KEY.REAL) {
            if((this.value / (10**(this.minimumPreDecimalPlaced-1))) > unit.formula-1) {
                this.convertedUnit = unit
                return (this.value / unit.formula).toLocaleString(this.local, {
                    minimumFractionDigits: this.fractionDigits,
                    maximumFractionDigits: this.fractionDigits
                })
            }
        }

        return this.round(this.value / this.convertedUnit.formula)
    }

    private modifyDateNumber() {
        const daysPerMonth = 30.436875
        // Der Wert muss aktuell in Tage angeben werden
        const days = this.value;
        let months = 0;
        let years = 0;
        if(days > daysPerMonth) {
            months = days / daysPerMonth;
        } else {
            if(this.round(days) == "1") {
                return this.round(days) + " " + this.getUnitTranslations('day');
            }
            return this.round(days) + " " + this.getUnitTranslations('days');
        }
        if(months > 13) {
            years = months / 12;
            if(this.round(years) == "1") {
                return this.round(years) + " " + this.getUnitTranslations('year');
            }
            return this.round(years) + " " + this.getUnitTranslations('years');
        }
        if(this.round(months) == "1") {
            return this.round(months) + " " + this.getUnitTranslations('month');
        }
        return this.round(months) + " " + this.getUnitTranslations('months');
    }

    private round(value: number):string {
        return (value).toLocaleString(this.local, {
            minimumFractionDigits: this.fractionDigits,
            maximumFractionDigits: this.fractionDigits
        })
    }

    private getUnitString () {
        if(this.convertedUnit.short == undefined) {
            return this.unit
        }
        if(this.unitAbbreviation && this.unitType != 'real') {
            return this.convertedUnit.short + this.unit
        }
        if(this.unitAbbreviation && this.unitType == 'real') {
            return this.getAbbrUnitTranslations(this.convertedUnit.short) + " " + this.unit
        }
        return this.getUnitTranslations(this.convertedUnit.name) + " " + this.unit
    }

    private getUnitTranslations(value: string) {
        if(value != "") {
            return this.i18n.t('unit.'+value)
        }
        return ""
    }

    private getAbbrUnitTranslations(value: string) {
        if(value != "") {
            return this.i18n.t('unit.short.'+value)
        }
        return ""
    }
}

