<template>
  <template v-if="inputElement && modelValue !== undefined">
    <label>
      {{ inputElement.label }}
      <span v-if="inputElement.icon" class="pointer" @click="inputElement.iconClickEvent? inputElement.iconClickEvent(): ''">
        <i :class="inputElement.icon"></i>
      </span>
    </label>
    <select
        :value="getSelectedValue(modelValue)"
        class="w-100"
        @change="handleChange"
        :disabled="inputElement.isDisable"
    >
      <template v-if="isObject(inputElement)">
        <option
            v-for="(option, index) of inputElement.optionValues"
            :key="index"
            :value="option.id"
        >
          {{ option.name }}
        </option>
      </template>
      <template v-else>
        <option
            v-for="(option, index) of inputElement.optionValues"
            :key="index"
            :value="option"
        >
          {{ option }}
        </option>
      </template>
    </select>
  </template>
</template>
<script lang="ts" setup>
import {PropType, defineProps, defineEmits} from "vue";
import {Input, SelectOption} from "../FormModalInterfaces";

const props = defineProps({
  inputElement: Object as PropType<Input>,
  modelValue: [String, Number, Boolean, Object] as PropType<string | number | boolean | SelectOption>
})

const emit = defineEmits(['update:modelValue'])

function isObject(value: Input) {
  if(!Array.isArray(value.optionValues)) {
    return false
  }
  return typeof value.optionValues[0] === 'object';
}

function getSelectedValue(value: string | number | boolean | SelectOption ): string | number | boolean {
  if (typeof value === 'object' && value !== null) {
    return (value as SelectOption).id;
  }
  return value as string | number | boolean;
}

function handleChange(event: Event) {
  if(!props.inputElement) return
  const target = event.target as HTMLSelectElement;
  const selectedValue = target.value;

  if (isObject(props.inputElement)) {
    const selectedOption = props.inputElement.optionValues.find(
        (option: SelectOption) => option.id.toString() === selectedValue
    );
    emit('update:modelValue', selectedOption);
  } else {
    emit('update:modelValue', selectedValue);
  }
}

</script>