import {defineStore} from "pinia";
import { BegUnit } from "./BegUnit"

export const useUnitConverter = defineStore('unitConverter', {
    state: () => ({
        locale: null as null|string,
        registeredValues: [] as BegUnit[]
    }),
    actions: {
        setLocale(localKey: string) {
            this.locale = localKey
        },
        /**
         * Fügt eine neuen Wert hinzu der formatiert und bearbeitet werden kann.
         * Hierbei sollte es sich um den Wert in der jeweiligen Basiseinheit handeln. z.B. Watt, Stunde, Ampere, Volt
         * @param value
         * @param baseUnit
         */
        add(value: number|string, baseUnit: string) {
            return new BegUnit(value, baseUnit);
        }
    }
})