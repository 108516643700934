<template>
    <div class="position-fixed position-top">
        <div class="flash-message-wrapper position-relative">
            <template v-for="flash of store.flashMessageItems" :key="flash.id">
                <FlashMessageTemplate v-if="flash.position == 'top'" :flash="flash" />
            </template>
        </div>
    </div>
    <div class="position-fixed position-bottom">
        <div class="flash-message-wrapper position-relative">
            <template v-for="flash of store.flashMessageItems" :key="flash.id">
                <FlashMessageTemplate v-if="flash.position == 'bottom'" :flash="flash" />
            </template>
        </div>
    </div>
</template>

<script lang="ts" setup>
import FlashMessageTemplate from './FlashMessageTemplate.vue';
import { useFlashMessageStore } from './flashMessageStore';

const store = useFlashMessageStore();

</script>

<style lang="scss" scoped>
.position-fixed {
    width: 100%;
    position: fixed;
    z-index: 500;
}

.position-bottom {
    bottom: 0;
}

.position-top {
    top: 0;
}

.flash-message-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
}
</style>