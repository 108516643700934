<template>
  <div class="container-default">
    <h1>Einheitenrechner</h1>
    <h3>Beispiel</h3>
    <div class="form grid mb-4">
      <div class="form-group g-col-3">
        <label>Wert</label>
        <input class="form-control" v-model="testInputValue"/>
      </div>
      <div class="form-group g-col-3">
        <label>Abgekürzt</label>
        <select v-model="shortUnit" class="form-control">
          <option :value="true">True</option>
          <option :value="false">False</option>
        </select>
      </div>
      <div class="form-group g-col-3">
        <label>Einheit</label>
        <input v-model="valueUnit" class="form-control"/>
      </div>
      <div class="form-group g-col-3">
        <label>Einheitentyp</label>
        <select v-model="valueUnitType" class="form-control">
          <option :value="'metric'">Metric</option>
          <option :value="'real'">Real</option>
          <option :value="'date'">Date</option>
        </select>
      </div>
      <div class="form-group g-col-3">
        <label>Min. Ziffern vor dem Komma</label>
        <input v-model="minimumPreDecimalPlaced" type="number" class="form-control"/>
      </div>
      <div class="form-group g-col-3">
        <label>Decimalstellen</label>
        <input v-model="fractionDigits" type="number" class="form-control"/>
      </div>
      <!--    <h5>Test ohne Vue Template</h5>
    <p>
  &lt;!&ndash;    {{ testValue }}&ndash;&gt;
    </p>-->
    </div>
    <div>
      <h5>Ausgabe</h5>
      <p>
        <strong>
          <BegUnit :value="testInputValue"
                   :is-abbreviation="shortUnit"
                   :unit-type="valueUnitType"
                   :unit="valueUnit"
                   :fraction-digits="fractionDigits"
                   :minimum-pre-decimal-placed="minimumPreDecimalPlaced"/>
        </strong>
      </p>
      <h5>Test mit Vue Template</h5>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {useUnitConverter} from "../../components/UnitConverter/unitConverterStore";
import {computed, ref} from "vue";
import BegUnit from "../../components/UnitConverter/BegUnit.vue";

const unitConvert = useUnitConverter();

const testInputValue = ref(0)
const shortUnit = ref(true)
const valueUnit = ref("")
const valueUnitType = ref("metric")
const fractionDigits = ref(2)
const minimumPreDecimalPlaced = ref(1)

const testValue = computed(() => {
  return 0 // unitConvert.add(testInputValue.value, "W").format()
})

</script>

<style lang="scss" scoped>
</style>