<template>
  <header>
    <nav>
      <router-link to="/" class="nav-item d-flex align-items-center px-3"><span>Flash Message</span></router-link>
      <router-link to="/baseElements" class="nav-item d-flex align-items-center px-3"><span>Basis Elemente</span></router-link>
      <router-link to="/formItems" class="nav-item d-flex align-items-center px-3"><span>Form Example</span></router-link>
      <router-link to="/infoModal" class="nav-item d-flex align-items-center px-3"><span>Info Modal</span></router-link>
      <router-link to="/unitConverter" class="nav-item d-flex align-items-center px-3"><span>Unit Converter</span></router-link>
      <router-link to="/apiConnection" class="nav-item d-flex align-items-center px-3"><span>API Test</span></router-link>
      <router-link v-if="userManager.isGranted('BACKEND_ADMIN')" to="/groupUsers" class="nav-item d-flex align-items-center px-3"><span>Group manager</span></router-link>
      <router-link to="/login" class="nav-item d-flex align-items-center px-3"><span>Login</span></router-link>
    </nav>
  </header>
  <router-view/>

  <ModalTemplates/>
</template>

<script lang="ts" setup>
import ModalTemplates from '../components/ModalTemplates.vue';
import {useFlashMessageStore} from '../components/FlashMessage/flashMessageStore';
import faIcon from "beg-icons/web/js/all.js"
import {onMounted} from "vue";
import {useI18n} from "vue-i18n";
import {useUserManagerStore} from "../components/UserManager/userManagerStore";

const flashMessageStore = useFlashMessageStore();
const userManager = useUserManagerStore()

const { locale } = useI18n();

onMounted(() => {
  userManager.isUserLoggedIn()
  locale.value = document.documentElement.lang;
})

</script>

<style lang="scss">
@import "beg-icons/web/css/all.css";

@import "~beg-stylings/scss/main.scss";
//@import "../../beg-stylings/scss/main";
</style>
