const METRIC = [
    {
        name: "Peta",
        short: "P",
        formula: 10 ** 15
    },
    {
        name: "Tera",
        short: "T",
        formula: 10 ** 12
    },
    {
        name: "Giga",
        short: "G",
        formula: 10 ** 9
    },
    {
        name: "Mega",
        short: "M",
        formula: 10 ** 6
    },
    {
        name: "Kilo",
        short: "k",
        formula: 10 ** 3
    },
    {
        name: "",
        short: "",
        formula: 1
    },

    {
        name: "milli",
        short: "m",
        formula: 10 ** -3
    },
    {
        name: "mikro",
        short: "µ",
        formula: 10 ** -6
    },
    {
        name: "nano",
        short: "n",
        formula: 10 ** -9
    }
]

export default METRIC