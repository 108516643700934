import axios from "axios";
import {defineStore} from "pinia";
import { useFlashMessageStore} from "../FlashMessage";
import {useUserManagerStore} from "../UserManager/userManagerStore";
import { ApiResponseInterface } from "./ApiRequestInterface";

axios.defaults.withCredentials = true;

export const useApiRequest = defineStore('apiRequest', {
    state: () => ({
        baseUrl: "https://localhost:8000/",
        runningRequest: 0
    }),
    actions: {
        setBaseUrl(value:string) {
            const userManagementStore= useUserManagerStore();
          this.baseUrl = value
            userManagementStore.setBaseUrl(value)
        },
        sendErrorMessage(response: any) {
            const flashMessage = useFlashMessageStore();
            if(!response) return
            if(response.message != "") {
                flashMessage.addFlashMessage(response.message, "danger")
            }
        },
        get(url: string) {
            const promise = new Promise<ApiResponseInterface>((resolve, reject) => {
                this.runningRequest++;
                axios.get(this.baseUrl + url).then((response) => {
                    this.runningRequest--;
                    resolve(response.data)
                }).catch((response) =>{
                    this.runningRequest--;
                    this.sendErrorMessage(response)
                    reject(response)
                })
            });
            return promise
        },
        post(url: string, data: any) {
            const promise = new Promise<ApiResponseInterface>((resolve, reject) => {
                this.runningRequest++;
                axios.post(this.baseUrl + url, data).then((response) => {
                    this.runningRequest--;
                    resolve(response.data)
                }).catch((response) =>{
                    this.runningRequest--;
                    this.sendErrorMessage(response)
                    reject(response)
                })
            });
            return promise
        },
        put(url: string, data: any) {
            const promise = new Promise<ApiResponseInterface>((resolve, reject) => {
                this.runningRequest++;
                axios.put(this.baseUrl + url, data).then((response) => {
                    this.runningRequest--;
                    resolve(response.data)
                }).catch((response) =>{
                    this.runningRequest--;
                    this.sendErrorMessage(response)
                    reject(response)
                })
            });
            return promise
        },
        delete(url: string) {
            const promise = new Promise<ApiResponseInterface>((resolve, reject) => {
                this.runningRequest++;
                axios.delete(this.baseUrl + url).then((response) => {
                    this.runningRequest--;
                    resolve(response.data)
                }).catch((response) =>{
                    this.runningRequest--;
                    this.sendErrorMessage(response)
                    reject(response)
                })
            });
            return promise
        }
    }
})