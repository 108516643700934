<template>
  <div class="text-center">
    <h1>Group users</h1>
    <ul ref="ulMenu">
      <template v-for="group of groupManagerStore.groupManagerResults" :key="group.id">
        <li @click="selectTab(group)" :class="{ active: group.isShowing }">{{ group.groupName }}</li>
      </template>
    </ul>
    <GroupManagerResult :group-manager-result="groupManagerStore.currentGroupManagerResult" />
    </div>
</template>

<script setup lang="ts">
import GroupManagerResult  from "./GroupManagerResult.vue" ;
import {GroupManagerResult as GroupManagerResultInterface } from "./GroupManagerInterfaces";
import {useGroupManagerStore} from "./groupManagerStore";
import {onMounted, ref} from "vue";

const groupManagerStore = useGroupManagerStore()

const ulMenu = ref<HTMLBaseElement|null>(null)


onMounted(() => {
  if(groupManagerStore.groupManagerResults.length > 0) {
    groupManagerStore.groupManagerResults[0].isShowing = true
    groupManagerStore.currentGroupManagerResult = groupManagerStore.groupManagerResults[0]
    groupManagerStore.getGroupMembers( groupManagerStore.currentGroupManagerResult ,true)
  }
})

function selectTab(element: GroupManagerResultInterface) {
  if(!ulMenu.value || !element) return

  for(const groupManagerResult of groupManagerStore.groupManagerResults) {
    groupManagerResult.isShowing = false
  }

  element.isShowing = true
  groupManagerStore.currentGroupManagerResult = element
  groupManagerStore.getGroupMembers(element,true)
}

</script>

<style lang="scss" scoped>
ul {
  list-style-type: none; /* Entfernt die Aufzählungspunkte */
  padding: 0ren; /* Entfernt den Standardabstand */
  margin: 0; /* Entfernt den Standardabstand */
  display: flex; /* Verwendet Flexbox für die horizontale Anordnung */
  justify-content: center;
  border-bottom: 0.2rem solid var(--lt-color-gray-500);;
}

li {
  cursor: pointer;
  padding: 1rem;
  transition: color, background-color 400ms;
}

.active {
  -webkit-border-top-left-radius: var(--border-radius-default);
  -webkit-border-top-right-radius: var(--border-radius-default);
  background-color: var(--lt-color-gray-500);
  color: white;
}

</style>