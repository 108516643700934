<template>
  <section>
    <div class="form form-border container-small">

      <h1>Formularfelder</h1>
      <div>
        <h3>Inputfeld</h3>
        <div class="grid">
          <div class="g-col-6">
            <div class="form-group mb-2">
              <label for="">Text-Input</label>
              <div class="form-input">
                <input type="text" id="" class="form-control" placeholder="Platzhalter-Text">
              </div>
              <div class="error-message">Fehlermeldung</div>
            </div>
          </div>
          <div class="g-col-6">
            <div class="form-group mb-2">
              <label for="">Zahl-Input</label>
              <div class="form-input">
                <input type="number" id="" class="form-control" placeholder="Platzhalter-Zahl">
              </div>
            </div>
          </div>
          <div class="g-col-6">
            <div class="form-group mb-2">
              <label for="">Datum-Input</label>
              <div class="form-input">
                <input type="date" id="" class="form-control" placeholder="Platzhalter-Datum">
              </div>
            </div>
          </div>
        </div>
        <div class="g-col-6">
          <div class="form-group mb-2">
            <div class="my-5">
              <label for="file-upload" class="file-upload button button-ghost-primary">
                <i class="fa-light fa-upload me-1"></i>
                Dateiupload</label>
              <input type="file" id="file-upload" class="d-none">
            </div>
          </div>
        </div>
        <div class="form-group mb-2">
          <label for="">Passwort</label>
          <div class="form-input">
            <input type="password" id="" class="form-control" placeholder="Passwort*" required>
          </div>
        </div>
        <div class="form-group mb-2">
          <label for="">E-Mail</label>
          <div class="form-input">
            <input type="email" id="" class="form-control" placeholder="E-Mail*" required>
          </div>
        </div>
        <div class="form-group mb-2">
          <label for="">Disabled Input</label>
          <div class="form-input">
            <input type="email" id="" disabled class="form-control" placeholder="Du kannst hier nix eingeben" >
          </div>
        </div>
        <div class="d-flex align-items-end">
          <div class="form-group">
            <label for="">E-Mail</label>
            <div class="form-input">
              <input type="email" id="" class="form-control" placeholder="E-Mail*" required>
            </div>
          </div>
          <div class="button button-primary">Buttontest</div>
        </div>
        <h3>Selectbox</h3>
        <div class="form-group mb-2">
          <select name="" class="form-control" id="">
            <option value="" disabled selected>disabled</option>
            <option value="1">Option 1</option>
            <option value="2">Option 2</option>
            <option value="3">Option 3</option>
            <option value="4">Option 4</option>
            <option value="5">Option 5</option>
          </select>
        </div>
        <h3>Checkboxen</h3>
        <div class="form-group mb-2 checkbox">
          <input type="checkbox" name="checkbox" id="checkbox">
          <label for="checkbox">Das ist eine Beispielcheckbox</label>
        </div>
        <div class="form-group d-flex align-items-center mb-2 checkbox">
          <input type="checkbox" class="toggle" name="checkbox" id="checkbox-toggle">
          <label for="checkbox-toggle">Das ist eine Beispielcheckbox als Toggle</label>
        </div>
        <h3>Radiobutton</h3>
        <div class="form-group mb-2 radio">
          <div class="radio-group">
            <input type="radio" name="radiogroup" id="radio1" value="" disabled>
            <label for="radio1">Value 1</label>
          </div>
          <div class="radio-group">
            <input type="radio" name="radiogroup" id="radio2">
            <label for="radio2">Value 2</label>
          </div>
          <div class="radio-group">
            <input type="radio" name="radiogroup" id="radio3">
            <label for="radio3">Value 3</label>
          </div>
        </div>
        <h3>Range-Slider</h3>
        <div class="form-group">
          <input type="range" min="1" max="100" value="50" class="range-slider">
        </div>
        <div class="form-group"></div>
      </div>

    </div>
  </section>

</template>
<script setup lang="ts">
</script>

<style scoped lang="scss">
/*  */
.file-upload {
  svg {
    --icon-size: 1.7rem;

    height: var(--icon-size);
    margin-right: 1rem;
    fill: var(--color-primary);
  }

  &:hover svg {
    fill: var(--color-white);
  }
}
</style>