<template>
  <div v-if="formItem" class="d-flex justify-content-start form-group mt-1 align-items-end">
    <template v-for="textBox of formItem.textBoxes" :key="textBox.uuid" >
      <p v-if="!textBox.link" :class="textBox.cssClass">
        {{ textBox.message }}
      </p>
      <a v-if="textBox.link" :href="textBox.link" :target="textBox.linkNewTarget? '_blank' : ''">
        {{ textBox.message }}
      </a>
    </template>
    <div v-for="input of formItem.inputs" :key="input.uuid" class="w-100 h-100" >
      <template v-if="input.type != 'select'">
        <InputDefault :input-element="input" v-model="input.value" @inputKeyDownEnterEvent="inputKeyDownEnterEvent" />
      </template>
      <template v-if="input.type == 'select' && input.optionValues">
        <SelectInputDefault :inputElement="input" v-model="input.value"/>
      </template>
      <template v-if="input.type == 'radio' && input.optionValues">
        <RadioInputDefault :inputElement="input" v-model="input.value"/>
      </template>
      <div class="error-message" v-if="input.isMissing">
        {{ input.errorMessage }}
      </div>
    </div>
    <button :disabled="button.isDisable" @click="buttonClickEvent(button)" v-for="button of formItem.buttons" :key="button.uuid" class="button" :class="'button-'+button.style">
      <i :class="button.faIcon"></i><span v-if="button.faIcon && button.label" class="ms-1"></span>{{ button.label }}
      <div v-if="button.isLoading" class="ms-1 loading-spinner"></div>
    </button>
  </div>
</template>

<script setup lang="ts">
import {defineProps, PropType} from "vue";
import {Button, Form, FormItem, Input} from "./FormModalInterfaces";
import InputDefault from "./FormInput/InputDefault.vue";
import SelectInputDefault from "./FormInput/SelectInputDefault.vue";
import RadioInputDefault from "./FormInput/RadioInputDefault.vue";

const props = defineProps({
  formItem: Object as PropType<FormItem>,
  form: Object as PropType<Form<any>>
})

function validateInputFields() {
  if(!props.form) return
  let noError = true
  const mandatoryInputs = [] as Input[]
  for(const formItem of props.form.formItems) {
    const result = formItem.inputs.filter(obj => obj.isMandatory)
    result.forEach(input => mandatoryInputs.push(input));
  }
  for(const input of mandatoryInputs) {
    if(input.value == "" || input.value == null) {
      input.isMissing = true
      noError = false
    } else {
      input.isMissing = false
    }
  }
  return noError;
}

function buttonClickEvent(button: Button) {
  if(button.isLoading || !props.form) return
  if(!validateInputFields() && !button.ignoreMandatory) return;
  button.isLoading = true
  if(button.onClickEvent) button.onClickEvent()
  if(button.closeFormOnClick) props.form.close()
}

function inputKeyDownEnterEvent() {
  if(!props.form) return
  // Ziehe alle Buttons die, die EnterEvent Funktion aktiviert haben
  const enterEventButtons = props.form.getAllButtons().filter(obj => obj.clickedByEnter)
  for(const button of enterEventButtons) {
    if(button.onClickEvent) buttonClickEvent(button);
  }
}


</script>