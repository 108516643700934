<template>
    <div class="overlay" @mousedown.self="backgroundClicked" v-if="form">
      <div class="info-modal container-small">
        <h3 v-if="form.header">{{ form.header }}</h3>
        <template v-for="formItem of allFormItems" :key="formItem.uuid">
          <FormItemTemplate :form-item="formItem" :form="form" />
        </template>
      </div>
  </div>
</template>

<script lang="ts" setup>
import {PropType, defineProps, computed} from "vue";
import {Form, FormItem} from "./FormModalInterfaces";
import FormItemTemplate from "./FormItemTemplate.vue"

const props = defineProps({
  form: Object as PropType<Form<any>>
})

function backgroundClicked() {
  if(!props.form) return
  if(!props.form.mandatory) props.form.close()
}

const allFormItems = computed(() => {
  const allFormItems = [] as FormItem[]
  if(!props.form) return allFormItems
  allFormItems.push(props.form.firstItem)
  for(const item of props.form.formItems) {
    allFormItems.push(item)
  }
  allFormItems.push(props.form.lastItem)
  return allFormItems
})
</script>