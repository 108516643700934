import {defineStore} from "pinia";
import axios from "axios";
import {User, UserFormInputs} from "./UserManagerInterfaces";

axios.defaults.withCredentials = true;

export const useUserManagerStore = defineStore('userManagerStore', {
    state: () => ({
        // user: Speichert die Benutzerinformationen oder ist null, wenn noch unbekannt ob User bereits angemeldet ist.
        // Wenn user auf false gesetzt ist, bedeutet dies, dass der Anmeldevorgang fehlgeschlagen ist oder User nicht angemeldet ist.
        user: null as User|null|false,
        baseUrl: "https://localhost:8000/"
    }),
    actions: {
        setBaseUrl(value: string) {
          this.baseUrl = value
        },
        tryToLogin(data: UserFormInputs) {
            const promise = new Promise((resolve, reject) => {
                axios.post(this.baseUrl + "login/json",  data).then((response) => {
                    this.user = JSON.parse(response.data.user);
                    return resolve(response)
                }).catch((data) => {
                    this.user = false
                    return reject(data.response);
                })
            })
            return promise
        },
        isUserLoggedIn() {
            axios.get(this.baseUrl + "login/getMe").then((response) => {
                this.user = JSON.parse(response.data.user);
            }).catch(() => {
                console.error("Missing Backend Connection")
            })
        },
        logout() {
            axios.get(this.baseUrl + "logout").then(() => {
                this.user = false;
            })
        },
        isGranted(value: string, backendSystem = "both" as "both"|"contao"|"marketing") {
            if(this.user == null || this.user == false) {
                return false;
            }
            if(backendSystem === "both") {
                let isFound = this.user.ssoRoles.find(role => role.name === value)?.name
                if(isFound) return true;
                isFound = this.user.roles.find(roleName => roleName === value)
                if(isFound) return true;
            }
            if(backendSystem === "marketing") {
                const isFound = this.user.roles.find(roleName => roleName === value)
                if(isFound) return true;
            }
            if(backendSystem === "contao") {
                const isFound = this.user.ssoRoles.find(role => role.name === value)?.name
                if(isFound) return true;
            }
        }
    }
})