import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia  } from 'pinia'

import translationMessages from "../components/translations";
import {createI18n} from "vue-i18n";

const pinia = createPinia()
const i18n =  createI18n({
    legacy: false,
    fallbackLocale: 'en',
    messages: translationMessages()
})

createApp(App)
.use(router)
.use(pinia)
    .use(i18n)
.mount('#app')