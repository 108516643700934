import {defineStore} from "pinia";
import axios from "axios";
import {useApiRequest} from "../ApiConnection/ApiRequest";
import {User} from "../UserManager/UserManagerInterfaces";
import {GroupManagerResult, GroupManagerSearchRequest} from "./GroupManagerInterfaces";
import {reactive} from "vue";

axios.defaults.withCredentials = true;

/**
 * Verwaltet die Gruppen die, nicht aus dem Contao Management kommen. Sondern die lediglich im Marketingmanager gesetzt werden.
 */
export const useGroupManagerStore = defineStore('groupManagerStore', {
    state: () => ({
        groupManagerResults: [] as GroupManagerResult[],
        currentGroupManagerResult: null as GroupManagerResult|null
    }),
    actions: {
        addGroup(groupName: string, isBackendGroup = true, description = "" )  {
            // Wenn der Gruppenname schon vorhanden ist wird dieser nicht nochmal hinzugefügt
            if(this.groupManagerResults.find(obj => obj.groupName === groupName)) return
            const groupManager = reactive(new GroupManagerResult());
            groupManager.isBackendGroup = isBackendGroup
            groupManager.groupName = groupName
            groupManager.description = description
            this.groupManagerResults.push(groupManager)
            return groupManager
        },
        getGroupMembers(groupManagerResult: GroupManagerResult, newSearch = false) {
            /// Bei einer neuer Suchanfrage werden die bisherigen Ergebnisse entfernt
            if(newSearch) {
                groupManagerResult.pageId = 1
                groupManagerResult.groupUsers = [] as User[]
                groupManagerResult.searchRequest.allDataFound = false
            } else {
                groupManagerResult.pageId++
            }
            groupManagerResult.searchRequest.isLoading = true
            return useApiRequest().get('api/v1/group/users/'+groupManagerResult.groupName+'/'+groupManagerResult.pageId + '/' +groupManagerResult.maxResult+groupManagerResult.searchRequest.getRequestString()).then((response )=> {
                groupManagerResult.searchRequest.lastResponse = response
                groupManagerResult.searchRequest.isLoading = false
                const foundUser = response.data as User[]
                // Wenn die Länge des Arrays kleiner ist als
                if((response.info.pagination?.page === response.info.pagination?.lastPage) || response.info.pagination?.lastPage === 0) {
                    groupManagerResult.searchRequest.allDataFound = true;
                }
                if(!foundUser) return
                for(const user of foundUser) {
                    groupManagerResult.groupUsers.push(user)
                }
            }).catch((error) => {
                console.error(error)
            })
        },
        addUserPerEmail(groupManagerResult: GroupManagerResult, emailAdresse: string) {
            return useApiRequest().post('api/v1/group/addUserByEmail/'+emailAdresse+'/'+groupManagerResult.groupName, []).then((response )=> {
                const user = response.data as User
                groupManagerResult.groupUsers.unshift(user);
            }).catch((error) => {
                console.error(error)
            })
        },
        removeUser(groupManagerResult: GroupManagerResult, user: User) {
            const userIndex = groupManagerResult.groupUsers.findIndex(obj => obj.id === user.id)
            return useApiRequest().delete('api/v1/group/removeUser/'+user.id+'/'+groupManagerResult.groupName).then(( )=> {
                groupManagerResult.groupUsers.splice(userIndex, 1)
            }).catch((error) => {
                console.error(error)
            })
        }
    }
})