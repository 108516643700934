import { defineStore } from 'pinia'
import {DefaultObject, Form} from "./FormModalInterfaces";
import {useI18n} from "vue-i18n";

export const useFormModalStore = defineStore('formModalStore', {
    state: () => ({
        formModalItems: [] as Form<any>[]
    }),
    actions: {
        /**
         *
         */
        add<objectType extends DefaultObject>(header: string|null = null):Form<objectType> {
            const formModal = new Form<objectType>(header);
            this.formModalItems.push(formModal)
            return formModal;
        },
        removeModal(form: Form<DefaultObject>) {
            const index = this.formModalItems.findIndex(obj => obj.uuid == form.uuid)
            if(index<0) return
            this.formModalItems.splice(index, 1)
        }
    }
})