<template>
  <div v-if="form">
    <div class="container-small">
      <h3 v-if="form.header">{{ form.header }}</h3>
      <template v-for="formItem of allFormItems" :key="formItem.uuid">
        <FormItemTemplate :form-item="formItem" :form="form" />
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>

import {defineProps, computed, PropType} from "vue";
import {Form, FormItem} from "./FormModalInterfaces";
import FormItemTemplate from "./FormItemTemplate.vue";

const props = defineProps({
  'form': Object as PropType<Form<any>>
})


const allFormItems = computed(() => {
  const allFormItems = [] as FormItem[]
  if(!props.form) return allFormItems
  allFormItems.push(props.form.firstItem)
  for(const item of props.form.formItems) {
    allFormItems.push(item)
  }
  allFormItems.push(props.form.lastItem)
  return allFormItems
})
</script>