import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import BaseElements from '@/views/BaseElements.vue'
import FormView from '@/views/FormView.vue'
import FlashMessageDemo from '../components/FlashMessageDemo.vue'
import InfoModalView from "@/views/InfoModalView.vue";
import UnitConvertView from "@/views/UnitConvertView.vue";
import LoginView from "@/views/LoginView.vue";
import ApiConnectionView from "@/views/ApiConnectionView.vue";
import GroupManagerExampleView from "@/views/GroupManagerExampleView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: FlashMessageDemo
  },
  {
    path: '/baseElements',
    name: 'baseElements',
    component: BaseElements
  },
  {
    path: '/formItems',
    name: 'formItems',
    component: FormView
  },
  {
    path: '/infoModal',
    name: 'infoModal',
    component: InfoModalView
  },
  {
    path: '/unitConverter',
    name: 'unitConverter',
    component: UnitConvertView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/apiConnection',
    name: 'apiConnection',
    component: ApiConnectionView
  },
  {
    path: '/groupUsers',
    name: 'groupUsers',
    component: GroupManagerExampleView
  },
  {
    path: '/form',
    name: 'form',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/FormView.vue')
  }
] as Array<RouteRecordRaw>

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
