<template>
  <div>
    <div v-for="formModal of formModalStore.formModalItems" :key="formModal.uuid">
      <FormModalTemplate :form="formModal" />
    </div>
  </div>
</template>

<script setup lang="ts">
import {useFormModalStore} from "./formModalStore";
import FormModalTemplate from "./FormModalTemplate.vue";

const formModalStore = useFormModalStore()
</script>

<style scoped lang="scss">

</style>