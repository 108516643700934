const REAL = [
    {
        name: "trillion",
        short: "t",
        formula: 10 ** 12
    },
    {
        name: "billions",
        short: "bn",
        formula: 10 ** 9
    },
    {
        name: "million",
        short: "mio",
        formula: 10 ** 6
    },
    {
        name: "thousand",
        short: "k",
        formula: 10 ** 3
    },
    {
        name: "",
        short: "",
        formula: 1
    },
]

export default REAL