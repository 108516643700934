export class UserFormInputs {
    email = ""
    password = ""
    rememberMe = false
}

export class User {
    id = 0;
    uuid = 0;
    name = ""
    firstname  = ""
    lastname = ""
    language = ""
    country = ""
    roles = [] as string[]
    ssoRoles = [] as ContaoRoles[]
    ssoEmail = "";
}

export class ContaoRoles {
    id =""
    name = ""
}