<template>
  <div>
    <div v-for="infoModal of infoModalStore.infoModalItems" :key="infoModal.id">
      <InfoModalTemplate :infoModal="infoModal" />
    </div>
  </div>
</template>

<script setup lang="ts">
import {useInfoModalStore} from "./infoModalStore";
import InfoModalTemplate from "./InfoModalTemplate.vue";

const infoModalStore = useInfoModalStore()
</script>

<style scoped lang="scss">

</style>