import {createI18n, I18n} from "vue-i18n";
import translationMessages from "./index";

export class I18nComsy {
    static instance = null as null|I18nComsy
    public i18n = null as null|I18n

    createI18n() {
        let locale = "en";

        if(document.documentElement.lang) {
            locale = document.documentElement.lang
        }
        this.i18n =  createI18n({
            legacy: false,
            locale: locale,
            fallbackLocale: 'en',
            messages: translationMessages()
        })


        return this.i18n.global
    }


    static getI18nInstance() {
        if(I18nComsy.instance == null) {
             I18nComsy.instance = new I18nComsy()
            I18nComsy.instance.createI18n()
        }
        return I18nComsy.instance.i18n?.global
    }
}