import {v4} from "uuid"
import {BaseColorClass, BaseGhostColorClass} from "../Interfaces";
import {useInfoModalStore} from "./infoModalStore";

export class InfoModal {
    id = v4()
    headerText = null as null|string
    infoTextItems = [] as InfoTextItem[];
    containerId = null as null|string
    buttonItems = [] as InfoTextButton[]
    closeViaBackground = true
    backgroundClickEvent: () => void = () => {}

    /**
     * Definiert die Information die auf dem Modal abgebildet wird.
     * @param infoText
     */
    addInfoText(infoText: string) {
        this.infoTextItems.push(new InfoTextItem(infoText))
        return this
    }

    /**
     * Optionales Feld welches auf dem Template als Überschrift angezeigt
     * @param infoText
     */
    setHeaderText(infoText: string) {
        this.headerText = infoText
        return this
    }

    /**
     * Zum Ansteuern des Containers mittels CSS
     * @param containerId
     */
    addStyleId(containerId: string) {
        this.containerId = containerId
        return this
    }

    /**
     * Fügt ein Button dem Template hinzu. Die Reihenfolge ist abhängig von dem Erstellungszeitpunkt. Weitere Button werden rechts dem vorhährigen angebunden
     * @param buttonText Text der im Button angezeigt wird
     * @param colorStyle Welchen Style soll der Button erhalten
     */
    addButton(buttonText: string, colorStyle = "ghost-primary" as BaseGhostColorClass|BaseColorClass) {
        const btn = new InfoTextButton(buttonText, colorStyle);
        this.buttonItems.push(btn)
        return btn;
    }

    /**
     * Schließt das Modal
     */
    close() {
        const infoModalStore = useInfoModalStore()
        infoModalStore.removeModal(this)
    }

    /**
     * Bestimmt, ob das Modal durch ein Klicken auf den Hintergrund geschlossen werden darf. Standardmäßig wird das Modal geschlossen.
     * Bei Bedarf kann auch eine Event ausgelöst werden. Das funktioniert sowohl be aktiviertem als auch deaktivieren "closeViaBackground"
     *
     * @param {boolean} value - TRUE => Modal darf durch den klick auf den Hintergrund geschlossen werden | FALSE => Blockiert den das schließen des Modals über den Hintergrund. Das Event wird dennoch ausgelöst
     * @param  clickEvent - Bestimmt die Funktion die nach dem Klickevent auf den Hintergrund ausgelöst wird.
     *
     * @returns {InfoModal}
     */
    setCloseViaBackground(value = false, clickEvent = null as null|(() => void) ) {
        this.closeViaBackground = value
        if(clickEvent) {
            this.backgroundClickEvent = clickEvent
        }
        return this
    }

    /**
     * Übergibt die Funktion die ausgelöst wird, sobald der Hintergrund geklickt wird
     * @param clickEvent
     * @return {InfoModal}
     */
    setBackgroundClickEvent(clickEvent: () => void) {
        this.backgroundClickEvent = clickEvent;
        return this
    }

}

export  class InfoTextItem {
    id = v4();
    text = "";

    constructor(text: string) {
        this.text = text;
    }

}

export class InfoTextButton {
    id = v4()
    buttonText = ""
    colorStyle = "primary" as BaseGhostColorClass|BaseColorClass
    autoCloseModal = true
    clickEvent: () => void = () => {}
    isLoading= false
    constructor(buttonText: string, colorStyle: BaseGhostColorClass|BaseColorClass) {
        this.setButtonText(buttonText)
        this.setColorStyle(colorStyle)
    }

    /**
     *
     * @param text
     * @return {InfoTextButton}
     */
    setButtonText(text: string) {
        this.buttonText = text
        return this
    }

    /**
     * Bestimmt die Farbe und Style des Buttons
     * @param colorStyle
     */
    setColorStyle(colorStyle: BaseGhostColorClass|BaseColorClass) {
        this.colorStyle = colorStyle;
    }

    /**
     * Standardmäßig sorgt der Button dafür, dass das Modal geschlossen wird sobald man darauf klickt. Hier kann es deaktiviert werden mit FALSE
     * @param value FALSE deaktiviert das Automatische schließen des Formular beim Klick auf dem Button
     */
    setAutoClose(value = false as boolean) {
        this.autoCloseModal = value
        return this
    }

    setIsLoading(value = true as boolean) {
        this.isLoading = value
        return this
    }

    /**
     * Bestimmt was nach dem Klicken des Buttons passieren soll.
     * @param clickEvent
     */
    isClicked(clickEvent: () => void) {
        this.clickEvent = clickEvent
    }
}