<template>
  <div class="form form-border container-small">
    <div class="grid">
      <div class="g-col-12">
        <h1>{{ $t('userManager.login') }}</h1>
      </div>
      <div class="g-col-12">
        <span v-if="userManagerStore.user === false">
          {{ $t('userManager.loginInfo') }}
        </span>
        <div v-if="userManagerStore.user">
          <span>Hallo {{ userManagerStore.user.name }}, <br>
          </span>
          {{ $t('userManager.signedInInfo') }}
        </div>
      </div>
      <div class="g-col-12" v-if="userManagerStore.user == null">
        <div><span class="loading-spinner"></span> Check Login status</div>
      </div>
      <div class="g-col-12" v-if="userManagerStore.user === false">
        <div class="form-group mb-2">
          <div class="form-group mb-2">
            <label for="">E-Mail</label>
            <div class="form-input">
              <input @keydown.enter="tryToLogin" v-model="data.email" type="email" id="" class="form-control" placeholder="E-Mail*" required>
            </div>
          </div>
          <label for="">Passwort</label>
          <div class="form-input">
            <input @keydown.enter="tryToLogin" v-model="data.password" type="password" id="" class="form-control" placeholder="Passwort*" required>
          </div>
          <div class="form-input mt-2">
            <button v-if="false" class="button button-ghost-secondary">{{$t('userManager.resetPassword')}}</button>
            <button v-if="false" class="button button-ghost-primary">{{$t('userManager.register')}}</button>
            <button class="button button-primary" @click="tryToLogin">{{$t('userManager.login')}}
              <span v-if="data.isLoading" class="ms-1 loading-spinner"></span>
            </button>
          </div>
        </div>
      </div>
      <div class="g-col-12" v-if="userManagerStore.user">
        <div class="form-input mt-2">
          <button class="button button-primary" @click="userManagerStore.logout()">{{$t('userManager.logout')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {useUserManagerStore} from "./userManagerStore";
import {onMounted, reactive} from "vue";
import {useFlashMessageStore} from "../FlashMessage";
import {useI18n} from "vue-i18n";
import {UserFormInputs} from "./UserManagerInterfaces";

const data = reactive({
  email: "",
  password: "",
  isLoading: false
})

const userManagerStore = useUserManagerStore();
const flashMessageStore = useFlashMessageStore();
const i18n = useI18n()

onMounted(() => {
  userManagerStore.isUserLoggedIn()
})

function tryToLogin() {
  let error = false
  let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  if(!mailformat.test(data.email)) {
    error = true
    flashMessageStore.addFlashMessage(i18n.t('userManager.noEmail'), "danger")
  }

  if(data.password == "") {
    error = true
    flashMessageStore.addFlashMessage(i18n.t('userManager.noPassword'), "danger")
  }
  if(error) return
  data.isLoading = true;
  const userFormInput = new UserFormInputs();

  userFormInput.email = data.email
  userFormInput.password = data.password

  userManagerStore.tryToLogin(userFormInput).then(() => {
    data.isLoading = false
  }).catch((dataResponse) => {
    if(dataResponse.data.errors) {
      for(const error of dataResponse.data.errors) {
          flashMessageStore.addFlashMessage( i18n.t('userManager.errorMessages.' + error.id ), 'danger')
      }
    }
    data.isLoading = false
  })
}
</script>