<template>
  <template v-if="inputElement && modelValue !== undefined">
    <div class="radio">
      <label>
        {{ inputElement.label }}
        <span v-if="inputElement.icon" class="pointer" @click="inputElement.iconClickEvent? inputElement.iconClickEvent(): ''">
          <i :class="inputElement.icon"></i>
        </span>
      </label>
      <template v-if="isObject(inputElement)">
        <div
            class="radio-group"
            v-for="(option, index) of inputElement.optionValues"
            :key="index"
            :value="option.id"
        >
          <input @click="handleChange" :id="'radio_'+index" :type="inputElement.type" :name="inputElement.uuid"
                 :value="option.id"
                 :checked="option.id == modelValue.id"/>
          <label :for="'radio_'+index">{{ option.name }}</label>
        </div>
      </template>
      <template v-else>
        <div
            class="radio-group"
            v-for="(option, index) of inputElement.optionValues"
            :key="index"
            :value="option"
        >
          <input @click="handleChange" :id="'radio_'+index" :type="inputElement.type" :name="inputElement.uuid"
                 :value="option"
                 :checked="option == modelValue"/>
          <label :for="'radio_'+index">{{ option }}</label>
        </div>
      </template>
    </div>
  </template>
</template>
<script lang="ts" setup>
import {PropType, defineProps, defineEmits} from "vue";
import {Input, SelectOption} from "../FormModalInterfaces";

const props = defineProps({
  inputElement: Object as PropType<Input>,
  modelValue: [String, Number, Boolean, Object] as PropType<string | number | boolean | SelectOption>
})

const emit = defineEmits(['update:modelValue'])

function isObject(value: Input) {
  if(!Array.isArray(value.optionValues)) {
    return false
  }
  return typeof value.optionValues[0] === 'object';
}

function getSelectedValue(value: string | number | boolean | SelectOption ): string | number | boolean {
  if (typeof value === 'object' && value !== null) {
    return (value as SelectOption).id;
  }
  return value as string | number | boolean;
}

function handleChange(event: Event) {
  if(!props.inputElement) return
  const target = event.target as HTMLSelectElement;
  const selectedValue = target.value;

  if (isObject(props.inputElement)) {
    const selectedOption = props.inputElement.optionValues.find(
        (option: SelectOption) => option.id.toString() === selectedValue
    );
    emit('update:modelValue', selectedOption);
  } else {
    emit('update:modelValue', selectedValue);
  }
}

</script>