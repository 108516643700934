<template>
  <span >
    {{resultValue}}
  </span>
</template>

<script lang="ts" setup>
import { defineProps, onMounted, PropType, ref, watch } from "vue"
import {useUnitConverter} from "./unitConverterStore";
import {BegUnit} from "./BegUnit";
import {Units} from "./interfaces";

const props = defineProps({
  value: [Number, String],
  unit: {
    type: String,
    default: ""
  },
  minimumPreDecimalPlaced: {
    type: Number,
    default: 2
  },
  fractionDigits: {
    type: Number,
    default: 2
  },
  isAbbreviation: {
    type: Boolean,
    default: true
  },
  unitType: {
    type: String as PropType<Units>,
    default: "metric"
  }
})

const begUnitObject = ref<null|BegUnit>()
const resultValue = ref("");

const unitConverter = useUnitConverter();

onMounted(() => {
  if(document.documentElement.lang) {
    unitConverter.setLocale(document.documentElement.lang)
  } else {
    unitConverter.setLocale("en")
  }

  if(props.value == undefined) return
  begUnitObject.value = unitConverter.add(props.value, props.unit)
  begUnitObject.value.setUnitType(props.unitType)
  begUnitObject.value.setIsUnitAbbreviation(props.isAbbreviation)

  buildResultValue()
})

watch(() => props.value, (newValue) => {
  if(!begUnitObject.value || newValue == undefined) return
  begUnitObject.value.setValue(newValue)
  buildResultValue()
})

watch(() => props.isAbbreviation, (newValue) => {
  if(!begUnitObject.value) return
  begUnitObject.value.setIsUnitAbbreviation(newValue)
  buildResultValue()
})

watch(() => props.unit, (newValue) => {
  if(!begUnitObject.value) return
  begUnitObject.value.setUnit(newValue)
  buildResultValue()
})

watch(() => props.unitType, (newValue) => {
  if(!begUnitObject.value) return
  begUnitObject.value.setUnitType(newValue)
  buildResultValue()
})

watch(() => props.minimumPreDecimalPlaced, () => {
  if(!begUnitObject.value) return
  buildResultValue()
})

watch(() => props.fractionDigits, () => {
  if(!begUnitObject.value) return
  buildResultValue()
})


function buildResultValue() {

  if(!begUnitObject.value) return
  resultValue.value = begUnitObject.value.format(props.minimumPreDecimalPlaced, props.fractionDigits)
}

</script>

<style scoped lang="scss">

</style>