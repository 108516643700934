<template>
  <div v-if="groupManagerResult">
  <h5 class="mt-2">{{ groupManagerResult.groupName }}</h5>
    <p class="m-0 pb-2">{{ groupManagerResult.description }}</p>
    <div class="container-small">
      <GroupUserSearchBar :group-manager-result="groupManagerResult" />
      <div class="mt-2">
        <template v-for="user of groupManagerResult.groupUsers" :key="user.id">
          <div class="mt-1">
            <GroupUserItem :user="user" :is-editable="groupManagerResult.isBackendGroup" />
          </div>
        </template>
      </div>
    </div>
    <div class="mt-5">
      <div v-if="groupManagerResult.searchRequest.isLoading" class="loading-spinner text-primary"></div>
      <button v-if="!groupManagerResult.searchRequest.allDataFound && !groupManagerResult.searchRequest.isLoading"
              class=" button button-primary" @click="getNextUser">Weitere Laden
      </button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {PropType, defineProps} from "vue";
import {GroupManagerResult} from "./GroupManagerInterfaces";
import {useGroupManagerStore} from "./groupManagerStore";
import GroupUserItem from "./GroupUserItem.vue";
import GroupUserSearchBar from "./GroupUserSearchBar.vue";

const props = defineProps({
  'groupManagerResult': Object as PropType<GroupManagerResult>
})

const groupManagerStore = useGroupManagerStore();
if(props.groupManagerResult) groupManagerStore.getGroupMembers(props.groupManagerResult)


function getNextUser() {
  if(!props.groupManagerResult) return
  groupManagerStore.getGroupMembers(props.groupManagerResult)
}

</script>

