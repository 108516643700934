<template>
  <div class="container">
    <div class="container-small">
    <div class="form-group">
      <label class="form-field">
        Method
      </label>
      <select class="form-select" v-model="data.method">
        <option>GET</option>
        <option>POST</option>
        <option>PUT</option>
        <option>DELETE</option>
      </select>
      <label class="form-field">URL</label>
      <input class="form-input" v-model="data.url" />
      <button
          @click="request"
          class="mt-1 button button-primary">
        Senden
      </button>
    </div>
    </div>
  </div>

</template>

<script lang="ts" setup>
import {reactive} from "vue";
import { useApiRequest} from "../../components/ApiConnection/ApiRequest";
import {useRegionStore} from "../../components/Region/regionStore";

const apiRequest = useApiRequest()
const regionStore = useRegionStore();

regionStore.init()

const data = reactive({
  method: "GET",
  url: ""
})

function request() {
  if(data.method === "GET") {
    apiRequest.get(data.url).catch(() => {
    })
  }
  if(data.method === "POST") {
    apiRequest.post(data.url, {}).catch(() => {
    })
  }
  if(data.method === "PUT") {
    apiRequest.put(data.url, {}).catch(() => {
    })
  }
  if(data.method === "DELETE") {
    apiRequest.delete(data.url).catch(() => {
    })
  }
}

</script>