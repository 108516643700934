import { defineStore } from 'pinia'
import {Country, Language, Region} from "./RegionInterfaces";
import {useApiRequest} from "../ApiConnection/ApiRequest";


export const useRegionStore = defineStore('regionStore', {
    state: () => ({
        regions: [] as Region[],
        languages: [] as Language[],
        countries: [] as Country[]
    }),
    actions: {
        init() {
            return new Promise<Region[]>((resolve) => {
                const request = useApiRequest().get('api/v1/region')
                request.then((response) => {
                    const regions = response.data as Region[]
                    this.regions = regions
                    resolve(regions)
                })
            })
        },
    }
})