<template>
  <div v-if="user" class="group-user-item border d-flex justify-content-between p-2">
    <div>
      {{ user.name }}
    </div>
    <div>
      <span v-if="isEditable" class="pointer" @click="removeUserFromGroup"><i class="fa-kit fa-beg-one-delete-circle"></i></span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {PropType, defineProps} from "vue";
import {User} from "../UserManager/UserManagerInterfaces";
import {useGroupManagerStore} from "./groupManagerStore";
import {useFormModalStore} from "../FormModal/formModalStore";

const props = defineProps({
  'user': Object as PropType<User>,
  'isEditable': {
    type: Boolean,
    default: true
  }
})

const groupManagerStore = useGroupManagerStore();

function removeUserFromGroup() {
  if(!props.user || !groupManagerStore.currentGroupManagerResult) return
  const form = useFormModalStore().add("Remove " + props.user.name)
  form.addLabel(true, "Are you sure you want to remove the user from group " + groupManagerStore.currentGroupManagerResult.groupName)
  form.addCloseButton()
  const deleteButton = form.addButton('lastLine', 'Remove', 'primary')

  deleteButton.onClick(async () => {
    if(!props.user || !groupManagerStore.currentGroupManagerResult) return
    await groupManagerStore.removeUser(groupManagerStore.currentGroupManagerResult, props.user)
    form.close()
  })
}

</script>

<style lang="scss" scoped>
.group-user-item {
  border-radius: var(--border-radius-default);
  border-color: var(--lt-color-gray-500);
}

</style>